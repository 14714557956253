.blogul li{
    list-style: circle;
    margin-bottom: 1.5rem;
    text-align: justify;
}
.blogul{
    padding-left: 2rem;
}
.divcontentfort2 h4{
    color: rgb(114, 113, 113);
    font-weight: 700;
    margin-top: 2.5rem;
    margin-bottom: 1rem;
}
/* .divcontentfort2 p{
    text-align: justify;
} */
p{
    text-align: justify;
}
.gallery img{
    min-height: -webkit-fill-available;
}
.gallery{
    display: flex;
}