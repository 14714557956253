.blogul1 li{
    list-style: disc;
    margin-bottom: 1.5rem;
    text-align: justify;
}
.blogul1{
    padding-left: 2rem;
}
.divcontentfort2 h4{
    color: rgb(114, 113, 113);
    font-weight: 700;
    margin-top: 2.5rem;
    margin-bottom: 1rem;
}
p{
    text-align: justify;
}