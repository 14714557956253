/*---------------------------
	Fonts
----------------------------*/
/*---------------------------
	Fonts
----------------------------*/
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700&display=swap');


// @font-face {
//     font-family: 'Futura PT Demi';
//     src: url('../../fonts/FuturaPT-Demi.woff2') format('woff2'),
//         url('../../fonts/FuturaPT-Demi.woff') format('woff');
//     font-weight: 600;
//     font-style: normal;
//     font-display: swap;
// }


// @font-face {
//     font-family: 'Futura PT Demi';
//     src: url('../../fonts/FuturaPT-DemiObl.woff2') format('woff2'),
//         url('../../fonts/FuturaPT-DemiObl.woff') format('woff');
//     font-weight: 600;
//     font-style: italic;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Futura PT';
//     src: url('../../fonts/FuturaPT-HeavyObl.woff2') format('woff2'),
//         url('../../fonts/FuturaPT-HeavyObl.woff') format('woff');
//     font-weight: 900;
//     font-style: italic;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Futura PT';
//     src: url('../../fonts/FuturaPT-Heavy.woff2') format('woff2'),
//         url('../../fonts/FuturaPT-Heavy.woff') format('woff');
//     font-weight: 900;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Futura PT';
//     src: url('../../fonts/FuturaPT-Light.woff2') format('woff2'),
//         url('../../fonts/FuturaPT-Light.woff') format('woff');
//     font-weight: 300;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Futura PT';
//     src: url('../../fonts/FuturaPT-LightObl.woff2') format('woff2'),
//         url('../../fonts/FuturaPT-LightObl.woff') format('woff');
//     font-weight: 300;
//     font-style: italic;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Futura PT';
//     src: url('../../fonts/FuturaPT-Medium.woff2') format('woff2'),
//         url('../../fonts/FuturaPT-Medium.woff') format('woff');
//     font-weight: 500;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Futura PT';
//     src: url('../../fonts/FuturaPT-MediumObl.woff2') format('woff2'),
//         url('../../fonts/FuturaPT-MediumObl.woff') format('woff');
//     font-weight: 500;
//     font-style: italic;
//     font-display: swap;
// }
@font-face {
    font-family: 'Jost';
    src: url('../../fonts/Jost-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Jost';
    src: url('../../fonts/Jost-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Jost';
    src: url('../../fonts/Jost-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Jost';
    src: url('../../fonts/Jost-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Jost';
    src: url('../../fonts/Jost-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Muli';
    src: url(../../fonts/Muli.ttf) format("truetype");
    font-weight: 600;
    font-style: normal;
  }

/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
    font-family: 'Muli';
    font-style: normal;
    font-weight: 500;
    src: local('Muli Regular'), url('../../fonts/text-font/Muli-Regular.woff') format('woff');
    }
    
    @font-face {
    font-family: 'Muli';
    font-style: normal;
    font-weight: 600;
    src: local('Muli SemiBold'), url('../../fonts/text-font/Muli-SemiBold.woff') format('woff');
    }
    
    @font-face {
    font-family: 'Muli';
    font-style: normal;
    font-weight: 700;
    src: local('Muli Bold'), url('../../fonts/text-font/Muli-Bold.woff') format('woff');
    }
    

    
// fonts
$base-font-size: 15;
$base-font: 'Muli';
$heading-font: 'Jost';
$heading-font-sub: 'Jost';



// color
$dark-gray: #303443;
$light-orange: #fde900;
$body-color: #687693;
$white: #fff;
$light: #8188a9;
$black: #000;
$small-black: #333;
$cyan: #848892;

$theme-primary-color: #fc4c4c;
$theme-primary-color-s2: #303443;
$body-bg-color: #fff;
$section-bg-color: #f3f8fc;
$text-color: #585858;
$text-light-color: #777777;
$heading-color: $dark-gray;
$border-color: #e9e9e9;
$border-color-s2: #d8e0f1;
