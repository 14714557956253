.conatact_view_div{
    margin-right: 1rem;
    width: 6%;
}
.contact_view_style_main_div{
    display: flex;
    flex-direction: row;
}
.event_input{
    margin: 2rem;
}