.rsuite_actions_btnCont {
    display: flex;
    max-width: max-content;
    margin: auto;
}

.rsuite_actions_btnCont>div {
    margin-right: 10px;
}

.rsuite_actions_btnCont>div:last-of-type {
    margin-right: 0;
}

.reactCustomTable_label {
    color: #3e89ae;
    font-size: 18px;
}

.reactCustomTable_cell .rs-table-cell-content {
    color: #444;
    font-weight: 400;
    font-size: 15px;
}